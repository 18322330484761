.App {
  font-family: sans-serif;
  text-align: center;
}
/* Pagination */
.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  max-width: 250px;
  margin: 0 auto;
  margin-bottom: 120px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  right: 0;
  width: 100%;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  background: rgba(182,182,182, 0.65) !important;
}

.swiper-pagination-lock {
  display: none;
}

/* moving pagination */
.swiper-pagination-bullet {
  width: 100%;
  height: 4px;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
  background: rgba(182,182,182, 0.65) !important;
  opacity: 0.85
}

.swiper-pagination-bullet::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.swiper-pagination-bullet-active::before {
  background-color: #F3F3F3;
  animation: progressBar 3s ease-in-out forwards;
  opacity: 1,
}

@keyframes progressBar {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@media(max-width: 1280px){
  .swiper-pagination {
    margin-bottom: 0;
  }
}