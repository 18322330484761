html,
body {
  overflow: hidden;
}

html,
body,
#root {
  height: 100%;
}

body b {
  display: none;
}

body .text-bold {
  display: unset;
}

/* TODO: remove this when swiper supports slidesPerView with automatic children width */
.swiper-slide:not(#swiper-sortableSectionContents) {
  width: auto !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: #e7e7e7;
  height: 32px;
  margin-top: 0;
  position: absolute;
  top: 45%;
  width: 32px;
}

.swiper-button-prev {
  left: 44px;
}

.swiper-button-next {
  right: 32px;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 1;
}

.swiper-button-next.swiper-button-disabled::after,
.swiper-button-prev.swiper-button-disabled::after {
  opacity: 0;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-prev::before {
  display: none;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-next::before {
  display: none;
}

.swiper-button-next.swiper-button-disabled + .swiper-button-container {
  display: none;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  position: absolute;
  bottom: 5px;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  font-size: 20px;
  color: #e7e7e7;
}

.swiper-pagination-bullet {
  background: #b6b6b6;
}

.swiper-pagination-bullet-active {
  background: #fff;
}

@media (max-width: 1200px) {
  .swiper-container {
    padding-left: initial;
    padding-right: initial;
  }
  .swiper-button-prev {
    display: none;
  }
  .swiper-button-next {
    display: none;
  }
  .swiper-button-container {
    display: none;
  }
}

.all-plans-swiper .swiper-button-prev {
  left: 0px;
}

.all-plans-swiper .swiper-button-prev::before {
  display: block;
  width: 400px;
  height: 200px;
  background: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.6));
  content: '';
}

.all-plans-swiper .swiper-button-next {
  right: 0px;
}

.all-plans-swiper .swiper-button-next::before {
  display: block;
  width: 400px;
  height: 200px;
  background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.6));
  content: '';
}

.text-white-super {
  color: #fff !important;
}

.containerIframeSpalla {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.no-text-selection {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}

.ps__rail-y {
  display: none;
}

#pushwooshBellWidget {
  display: none;
}

.PhoneInput {
  background-color: #141414;
  border-radius: 4px;
  color: white;
  height: 60%;
  margin-top: 6px;
  outline: 0.5px solid;
  outline-color: black;
  padding: 4%;
  width: 100%;
}

.PhoneInput--focus {
  outline: 2px solid !important;
  outline-color: white !important;
}

.PhoneInput:hover {
  outline: none;
}

.PhoneInputInput {
  /* The phone number input stretches to fill all empty space */
  background-color: #141414;
  border: none;
  color: #b6b6b6;
  flex: 1;
  font-family: Roboto;
  font-weight: 500;
  /* The phone number input should shrink
         to make room for the extension input */
  min-width: 0;
}

.PhoneInputInput:focus {
  outline: none;
}

.video-react-big-play-button {
  display: none;
}
